import model from './model';
import { FeaturedCollectionsWidgetComponentIds as ComponentIds } from './config/constants';
import { queryCollections } from '@wix/ambassador-portfolio-collections-v1-collection/http';
import {
  Collection,
  SortOrder,
} from '@wix/ambassador-portfolio-collections-v1-collection/types';
import { queryProjectsWithCollectionInfo } from '@wix/ambassador-portfolio-projects-v1-project/http';
import { Project } from '@wix/ambassador-portfolio-projects-v1-project/types';
import { getCollectionProjects, getCollections } from '../../services/dataServices';

export default model.createController((controllerParams) => {
  const { $w, $widget, flowAPI } = controllerParams;
  const { httpClient } = flowAPI;
  const { isSSR } = flowAPI.environment;
  const shouldShowEmptyComponent = isSSR;

  let components: ReturnType<typeof getComponents>;
  let collections: Collection[] = [];
  let collectionProjects: Project[] = [];

  const getComponents = () => ({
    topContainer: $w(ComponentIds.Root),
    multiStateBoxWidget: $w(ComponentIds.MultiStateWidget),
  });

  const getSelectedCollections = () => {
    let filteredCollections: Collection[] = [];
    const selectedCollectionIds = $widget.props.data.selectedCollections;
    if (!selectedCollectionIds) {
      filteredCollections = [collections[0]];
    } else {
      filteredCollections = collections.filter((collection: Collection) => {
        const isCollectionSelected = selectedCollectionIds.includes(
          collection.id,
        );
        return isCollectionSelected;
      });
    }
    return filteredCollections;
  };

  const initFeaturedWidgetData = async () => {
    const selectedCollectionsData = getSelectedCollections();
    if (selectedCollectionsData.length === 1) {
      const projectsInCollection = await getCollectionProjects(
        httpClient,
        selectedCollectionsData[0].id as string,
      );
      collectionProjects = projectsInCollection.map(
        (p) => p.project,
      ) as Project[];
    }
    components.multiStateBoxWidget.data = {
      collections: selectedCollectionsData,
      collectionProjects,
    };
  };
  const initEmptyCollection = async () => {
    components.multiStateBoxWidget.data = { collections: [] };
  };
  const initFeaturedWidget = async () => {
    try {
      collections = await getCollections(httpClient);
      await initFeaturedWidgetData();
    } catch (e) {
      if (shouldShowEmptyComponent) {
        await initEmptyCollection();
      }
    }
  };
  return {
    pageReady: async () => {
      components = getComponents();
      await initFeaturedWidget();
    },
    exports: {},
  };
});
