import model from './model';
import {
  NavigationButtonWidgetComponentIds as ComponentIds,
  NavigationButtonWidgetEvents as Events,
} from './config/constants';
import { NavigationButtonWidgetProps as Props } from './config/types';

export default model.createController((controllerParams) => {
  const { $w, $widget } = controllerParams;

  let components: ReturnType<typeof getComponents>;

  const getComponents = () => ({
    topContainer: $w(ComponentIds.Root),
    buttonBox: $w(ComponentIds.ButtonBox),
    textButton: $w(ComponentIds.TextButton),
    arrowButton: $w(ComponentIds.ArrowButton),
  });

  const initByProps = (props: Props) => {
    const { data } = props;
  };

  const initEventHandlers = () => {
    components?.buttonBox?.onClick(() => {
      $widget.fireEvent(Events.Clicked, {});
    });
  };

  $widget.onPropsChanged((_, nextProps) => {
    initByProps(nextProps);
    initEventHandlers();
  });

  return {
    pageReady: async () => {
      components = getComponents();
      const isWidgetOnStage = !!components.topContainer.id;
      if (isWidgetOnStage) {
        initEventHandlers();
        initByProps($widget.props);
      }
    },
    exports: {},
  };
});
