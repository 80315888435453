import model from './model';
import { CollectionPageWidgetComponentIds as ComponentIds } from './config/constants';
import { ProjectInCollection } from '@wix/ambassador-portfolio-projects-v1-project/types';
import { Collection } from '@wix/ambassador-portfolio-collections-v1-collection/types';
import { getPortfolioBaseUrl } from '../../utils/urlsUtils';
import { getPageViewMode, isSeoTagsEnabled } from '../../utils/viewerUtils';
import { portfolioPageViewed } from '@wix/bi-logger-portfolio-users/v2';
import {
  getCollectionBySlug,
  getFirstCollection,
  getProjects,
} from '../../services/dataServices';

export default model.createController((controllerParams) => {
  const { $w, $widget, flowAPI } = controllerParams;
  const { isViewer, isEditor } = flowAPI.environment;
  const { httpClient } = flowAPI;
  const biLogger = flowAPI.essentials.biLoggerFactory().logger();

  try {
    // @ts-expect-error
    console.log('HttpClient baseUrl ' + flowAPI.httpClient.opts.baseURL);
    // @ts-expect-error
    console.log('HttpClient SSR ' + flowAPI.httpClient.opts.isSSR);
  } catch (e) {
    console.error('Logs error', e);
  }
  let components: ReturnType<typeof getComponents>;
  let currCollection: Collection = {};
  let projectsInCollection: ProjectInCollection[] = [];

  const getComponents = () => ({
    topContainer: $w(ComponentIds.Root),
    navigationWidget: $w(ComponentIds.NavigationWidget),
    collectionWidget: $w(ComponentIds.CollectionWidget),
  });

  const initCollectionPageData = async () => {
    components.navigationWidget.data = {
      hideNextButton: true,
      hideBackButton: false,
    };

    const routerData =
      flowAPI.controllerConfig.wixCodeApi.window.getRouterPublicData();
    // @ts-expect-error
    currCollection = routerData.collectionPageData.collection;
    // @ts-expect-error
    projectsInCollection = routerData.collectionPageData.collectionProjects;

    if (!currCollection) {
      currCollection = isViewer
        ? ((await getCollectionBySlug(flowAPI)) as Collection)
        : ((await getFirstCollection(httpClient)) as Collection);
    }

    if (!projectsInCollection && currCollection.id) {
      projectsInCollection = await getProjects(httpClient, currCollection.id);
    }

    components.collectionWidget.data = {
      collection: currCollection,
      projects: projectsInCollection.map((p) => p.project),
    };
  };

  const handleInteractions = () => {
    if (!isEditor) {
      const url = getPortfolioBaseUrl(flowAPI);
      components.navigationWidget.onBackClicked(() => {
        flowAPI.controllerConfig.wixCodeApi.location.to &&
          flowAPI.controllerConfig.wixCodeApi.location.to(url);
      });
    }
  };
  const initCollectionPage = async () => {
    handleInteractions();
    await initCollectionPageData();
  };
  const onPropsChanged = async () => {
    await initCollectionPage();
  };
  return {
    pageReady: async () => {
      components = getComponents();
      const isWidgetOnStage = !!components.topContainer.id;
      if (isWidgetOnStage) {
        const isFirstTime =
          // @ts-expect-error
          !flowAPI.controllerConfig?.livePreviewOptions?.compsIdsToReset;
        isFirstTime ? await initCollectionPage() : await onPropsChanged();
        biLogger.report(
          portfolioPageViewed({
            pageName: 'collection',
            viewMode: getPageViewMode(flowAPI),
          }),
        );

        if (isSeoTagsEnabled(flowAPI)) {
          flowAPI.controllerConfig.wixCodeApi.seo.renderSEOTags({
            itemType: 'PORTFOLIO_COLLECTIONS',
            itemData: { collection: currCollection },
            seoData: {},
          });
        }
      }
    },
    exports: {},
  };
});
