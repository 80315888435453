import model from './model';
import { PortfolioPageWidgetComponentIds as ComponentIds } from './config/constants';
import { Collection } from '@wix/ambassador-portfolio-collections-v1-collection/types';
import { portfolioPageViewed } from '@wix/bi-logger-portfolio-users/v2';
import { getPageViewMode } from '../../utils/viewerUtils';

import { Project } from '@wix/ambassador-portfolio-projects-v1-project/types';
import {
  getCollectionProjects,
  getCollections,
} from '../../services/dataServices';

export default model.createController((controllerParams) => {
  const { $w, $widget, flowAPI } = controllerParams;
  const biLogger = flowAPI.essentials.biLoggerFactory().logger();
  const { isViewer, isSSR } = flowAPI.environment;
  const { httpClient } = flowAPI;
  const shouldShowEmptyComponent = isSSR;

  let components: ReturnType<typeof getComponents>;
  let collections: Collection[] = [];
  let collectionProjects: Project[] = [];

  const getComponents = () => ({
    topContainer: $w(ComponentIds.Root),
    multiStateBoxWidget: $w(ComponentIds.MultiStateWidget),
  });

  const initPortfolioPageData = async () => {
    components.multiStateBoxWidget.data = { collections, collectionProjects };
  };
  const initEmptyCollection = async () => {
    components.multiStateBoxWidget.data = { collections: [] };
  };

  const initPortfolio = async () => {
    try {
      collections = await getCollections(httpClient);
      if (collections.length === 1) {
        const projectsInCollection = await getCollectionProjects(
          httpClient,
          collections[0].id as string,
        );
        collectionProjects = projectsInCollection.map(
          (p) => p.project,
        ) as Project[];
      }
      await initPortfolioPageData();
    } catch (e) {
      if (shouldShowEmptyComponent) {
        await initEmptyCollection();
      }
    }
  };

  return {
    pageReady: async () => {
      components = getComponents();
      await initPortfolio();
      biLogger.report(
        portfolioPageViewed({
          pageName: 'portfolio',
          viewMode: getPageViewMode(flowAPI),
        }),
      );
    },
    exports: {},
  };
});
